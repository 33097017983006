import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import DashboardLayout from "../layouts/DashboardLayout";
import isAuthenticatedGuard from "@/router/auth-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    name: "ToLogin",
  },
  {
    path: "/",
    component: DefaultLayout,
    name: "DefaultLayout",
    children: [
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      },
      {
        path: "/login/resetpassword",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "ResetPassword" */ "../views/account/ResetPassword.vue"
          ),
      },
      {
        path: "/recover/:token",
        name: "RecoverPassword",
        component: () =>
          import(
            /* webpackChunkName: "RecoverPassword" */ "../views/account/RecoverPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "Home",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/dashboard/user/permissions",
        name: "UserPermissions",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "UserPermissions" */ "@/views/dashboard/user/permissions/permissions.vue"
          ),
      },
      {
        path: "/dashboard/user/password",
        name: "UserChangePassword",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "UserChangePassword" */ "@/views/dashboard/user/password/changePassword.vue"
          ),
      },

      {
        path: "/list-action-plans/:serviceId",
        name: "HomeActionPlans",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/list-action-plans/index.vue"
          ),
      },

      {
        path: "/list-evidence-action-plans",
        name: "ListActionPlanEvidences",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/list-action-plans-service/index.vue"
          ),
      },

      {
        path: "/create-action-plans",
        name: "CreateActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/create-action-plans/index.vue"
          ),
      },

      {
        path: "/list-action-plans-service/:evidenceActionPlan",
        name: "editPeriodActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "editPeriodoActionPlan" */ "@/views/dashboard/action-plans/edit-periodo-action-plans/index.vue"
          ),
      },

      {
        path: "/list-action-plans-service/:serviceId/action-plans/:actionPlanId/edit",
        name: "EditActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "EditActionPlan" */ "@/views/dashboard/action-plans/edit-action-plans/index.vue"
          ),
      },

      {
        path: "/action-plans/detail-action-plan-grouped-service/:serviceId/detail-action-plan/:actionPlanId",
        name: "DetailActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/detail-action-plan/index.vue"
          ),
      },

      {
        path: "/action-plans/detail-action-plan-grouped-service/:serviceId",
        name: "DetailActionPlanGroupedService",
        meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/detail-action-plan-grouped-service/index.vue"
          ),
      },

      {
        path: "/action-plans/detail-action-plan-grouped-service/:serviceId/detail-action-plan/:actionPlanId/detail-evidence-action-plan/:evidenceActionPlanId",
        name: "DetailEvidenceActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/detail-evidence-action-plan/index.vue"
          ),
      },

      {
        path: "/list-evidence-action-plans/upload-evidence/:evidenceId",
        name: "UploadEvidenceActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/upload-evidence-action-plan/index.vue"
          ),
      },

      {
        path: "/list-action-plans-service/:serviceId/action-plans/observed-upload-evidence-action-plan/:actionPlanId",
        name: "ObservedUploadEvidenceActionPlan",
        // meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/observed-evidence-action-plan/index.vue"
          ),
      },

      {
        path: "/action-plans/report-action-plans",
        name: "ReportActionPlans",
        meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/report-action-plans/index.vue"
          ),
      },

      {
        path: "/action-plans/list-evidence-action-plan",
        name: "ListEvidenceActionPlan",
        // meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/list-evidences-action-plan/index.vue"
          ),
      },

      {
        path: "/dashboard-action-plan",
        name: "DashboardActionPlan",
        meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/dashboardActionPlan/index.vue"
          ),
      },

      //list action plan satisfaction
      {
        path: "/list-evidence-action-plans-satisfaction",
        name: "ListActionPlanEvidencesSatifaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/list-action-plan-satisfaction/index.vue"
          ),
      },
      {
        path: "/create-action-plans-satisfaction",
        name: "CreateActionPlanSatifaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/create-action-plan-satisfaction/index.vue"
          ),
      },
      {
        path: "/edit-action-plan-satisfaction/:evidenceActionPlan",
        name: "editPeriodActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
             /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/edit-period-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/list-evidence-action-plans-satisfaction/upload-evidence-satisfaction/:evidenceId",
        name: "UploadEvidenceActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/upload-evidence-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/action-plans-satisfaction/report-action-plans-satisfaction",
        name: "ReportActionPlansSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/report-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/dashboard-action-plan-satisfaction",
        name: "DashboardActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'dashboardPlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/dashboard-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/list-evidence-action-plans-satisfaction-control",
        name: "ListEvidenceActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/list-evidence-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/action-plans-satisfaction/detail-action-plan-grouped-service/:serviceId/detail-action-plan/:actionPlanId/detail-evidence-action-plan/:evidenceActionPlanId",
        name: "DetailEvidenceActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/detail-evidence-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/action-plans-satisfaction/detail-action-plan-grouped-service/:serviceId/detail-action-plan/:actionPlanId",
        name: "DetailActionPlanSatisfaction",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/detail-action-plan-satisfaction/index.vue"
          ),
      },

      {
        path: "/action-plans-satisfaction/detail-action-plan-grouped-service/:serviceId",
        name: "DetailActionPlanSatisfactionGroupedService",
        meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/detail-action-plan-satisfaction-grouped-service/index.vue"
          ),
      },

      //[TODO] nombre modulo y page name
      {
        path: "/action-plans-notification",
        name: "NotificationActionPlan",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans/notification-action-plan/index.vue"
          ),
      },

      {
        path: "/action-plans-satisfaction-notification",
        name: "NotificationActionPlanSatisfaction",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-satisfaction/notification-action-plan-satisfaction/index.vue"
          ),
      },

      //[TODO] nombre modulo y page name
      {
        path: "/plan-type/create",
        name: "CreatePlanType",
        // meta: { moduleName: 'tipoPlanDinamico', pageName: 'tipoPlan' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/plan-type/create-plan-type/index.vue"
          ),
      },

      {
        path: "/plan-type/update/:planTypeId",
        name: "UpdatePlanType",
        // meta: { moduleName: 'tipoPlanDinamico', pageName: 'tipoPlan' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/plan-type/update-plan-type/index.vue"
          ),
      },

      {
        path: "/plan-type/list",
        name: "ListPlanType",
        // meta: { moduleName: 'tipoPlanDinamico', pageName: 'tipoPlan' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/plan-type/list-plan-type/index.vue"
          ),
      },

      {
        path: "/plan-type/detail/:planTypeId",
        name: "DetailPlanType",
        // meta: { moduleName: 'tipoPlanDinamico', pageName: 'tipoPlan' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/plan-type/detail-plan-type/index.vue"
          ),
      },

     //[TODO] nombre modulo y page name 
      {
        path: "plan-type/:planTypeId/list-evidence-action-plans",
        name: "ListEvidenceActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/action-plans-dinamic/list-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/create-action-plan",
        name: "CreateActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/action-plans-dinamic/create-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/edit-period-action-plan/:evidenceActionPlanId",
        name: "EditPeriodActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/action-plans-dinamic/edit-period-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/upload-evidence-action-plan/:evidenceActionPlanId",
        name: "UploadEvidenceActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            "@/views/dashboard/action-plans-dinamic/upload-evidence-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/action-plans/report-action-plans",
        name: "ReportActionPlansDinamic",
        // meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/report-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/list-evidence-action-plans-control",
        name: "ControlEvidencesActionPlanDinamic",
        // meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/list-evidences-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/action-plans/detail-evidence-action-plan/:evidenceActionPlanId",
        name: "DetailEvidenceActionPlanDinamic",
        // meta: { moduleName: 'climaLaboral', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/detail-evidence-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/detail-action-plan-grouped-service/:serviceId",
        name: "DetailActionPlanGroupedServiceDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/detail-action-plan-dinamic-grouped-service/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/service/:serviceId/detail-action-plan/:actionPlanId",
        name: "DetailActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/detail-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/dashboard-action-plan",
        name: "DashboardActionPlanDinamic",
        // meta: { moduleName: 'climaLaboral', pageName: 'listaPlanesAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/dashboard-action-plan-dinamic/index.vue"
          ),
      },

      {
        path: "plan-type/:planTypeId/action-plans-notification",
        name: "NotificationActionPlanDinamic",
        // meta: { moduleName: 'satisfaccionCliente', pageName: 'reportePlanAccion' },
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(
            /* webpackChunkName: "PermissionsPage" */ "@/views/dashboard/action-plans-dinamic/notification-action-plan-dinamic/index.vue"
          ),
      },


      ///
      {
        path: "/401",
        name: "NotAuthorized",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(/* webpackChunkName: "NotAuthorized" */ "../views/401.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        beforeEnter: isAuthenticatedGuard,
        component: () =>
          import(/* webpackChunkName: "NotFound" */ "../views/404.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
