<template>
	<EditPeriodActionPlanDinamic />
</template>
<script>

import EditPeriodActionPlanDinamic from '@/components/action-plan-dinamics/edit-period-action-plan-dinamic/EditPeriodActionPlanDinamic.vue'

export default {
	name: 'EditPeriodActionPlanDinamicPage',
	components: { EditPeriodActionPlanDinamic },
};
</script>
