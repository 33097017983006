<template>
	<CreateActionPlanDinamic />
</template>
<script>

import CreateActionPlanDinamic from '@/components/action-plan-dinamics/create-action-plan-dinamic/CreateActionPlanDinamic.vue'

export default {
	name: 'CreateActionPlanDinamicPage',
	components: { CreateActionPlanDinamic },
};
</script>
