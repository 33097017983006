<template>
	<DetailActionPlanDinamic />
</template>
<script>

import DetailActionPlanDinamic from '@/components/action-plan-dinamics/detail-action-plan-dinamic/DetailActionPlanDinamic.vue'

export default {
	name: 'DetailActionPlanDinamicPage',
	components: { DetailActionPlanDinamic },
};
</script>
