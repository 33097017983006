import { mapActions, mapState } from "vuex";
import DateSelectedFieldDinamic from "@/components/action-plan-dinamics/components/selectedDateFieldDinamic/index.vue";
import ValidateActivity from '@/components/global/ValidateActivity.vue';
import { showSnackBar } from "@/helpers/globalHelpers";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
    name: "editPeriodoActionPlanDinamic",

    data: () => ({
        isLoadingPlanType: false,
        loadingSend: false,
        searchQuestion: "",
        isValidForm: null,
        isLoading: false,
        isLoadingUsers: false,
        isLoadingManagements: false,
        requiredRule: [(v) => !!v || "Campo requerido"],
        selectedManagement: null,
        formUpdatePeriodAP: {
            description: '',
            period: '',
            service_manager: '',
            th_manager: ''
        },
        headersUpdate: [
            { text: "Valor anterior", sortable: false, value: "new_value" },
            { text: "Nuevo valor", sortable: false, value: "old_value" },
            { text: "Propiedad", sortable: false, value: "field" },
            { text: "Estado", sortable: false, value: "status" },
        ],
        headersApprovers: [
            { text: "Usuario", sortable: false, value: "response_user_id.fullName" },
            { text: "Estado", sortable: false, value: "response" },
        ]
    }),

    created() {
        this.loadUsers();
        this.loadEvidenceActionPlan();
        this.listCostCenter();
        this.getPlanType();
    },

    computed: {
        ...mapState("security", ["usersActive", "costCenters", "allManagementsState"]),
        ...mapState("action_plan", ["actionPlan"]),
        ...mapState("evidence_action_plan", ["evidenceActionPlan"]),
        ...mapState("plan_type", ["planType"]),
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Planes de acción",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "ListEvidenceActionPlanDinamic",
                        planTypeId: this.planType?.id
                    },
                },
                {
                    text: "Editar periodo de plan de acción",
                    link: true,
                    exact: true,
                    disabled: true,
                },
            ];
        },
        populatedApprovers() {
            if (this.evidenceActionPlan && this.evidenceActionPlan.status === "MODIFICADO") {
                const approversSet = new Set();
                return this.evidenceActionPlan.action_plan.action_plan_modification_histories.reduce((acc, history) => {
                    const populatedApprovers = history.approvers.map(approver => {
                        const user = this.usersActive.find(user => user.id === approver.response_user_id);
                        if (user && !approversSet.has(user.id)) {
                            approversSet.add(user.id);
                            return {
                                ...approver,
                                response_user_id: user
                            };
                        }
                        return approver;
                    }).filter(approver => approversSet.has(approver.response_user_id.id));
                    acc.push(...populatedApprovers);
                    return acc;
                }, []);
            }
            return [];
        },
        profiles() {
            // let permissions = [];
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = []
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role)
                        // profile.modules.forEach((module) => {
                        // 	module.pages.forEach((page) => {
                        // 		page.activities.forEach((activity) => {
                        // 			permissions.push({
                        // 				company_name: company?.name,
                        // 				profile_name: profile?.role?.name,
                        // 				module_name: module?.name,
                        // 				page_name: page?.name,
                        // 				activity_name: activity?.name,
                        // 			});
                        // 		});
                        // 	});
                        // });
                    });
                });
            }
            return profiles || [];
        },
        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );

            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },
    },

    methods: {
        ...mapActions("security",
            [
                "getUsersActive",
                "allManagements",
            ]),
        ...mapActions('action_plan', [
            'proposalUpdateActionPlan',
            'replyProposalUpdateActionPlan'
        ]),
        ...mapActions('evidence_action_plan',
            [
                'getEvidenceActionPlanById',
                'cleanEvidenceActionPlanId'
            ]),

        ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }

            this.isLoadingPlanType = false;
        },

        listCostCenter: async function () {
            const { error } = await this.allManagements({ active: true });
            if (error) showError(error);
        },

        loadUsers: async function () {
            this.isLoadingUsers = true;
            await this.getUsersActive({ active: 1 });
            this.isLoadingUsers = false;
        },

        loadEvidenceActionPlan: async function () {
            const evidenceActionPlanId = this.$route.params?.evidenceActionPlanId;

            if (evidenceActionPlanId) {
                this.isLoading = true;
                const data = await this.getEvidenceActionPlanById(evidenceActionPlanId);
                if (data) {
                    this.formUpdatePeriodAP = {
                        description: data?.response?.action_plan.description,
                        period: moment.utc(data?.response?.register_date).format("YYYY-MM-DD"),
                        service_manager: data?.response?.action_plan?.service?.management_responsible_user_id,
                        th_manager: this.allManagementsState.find(management => management.name === 'GERENCIA TH')?.responsible_id?.id,
                    }
                }
                this.isLoading = false;
            }
        },

        saveEvidenceActionPlan: async function () {
            this.loadingSend = true;
            let data = {
                description: this.formUpdatePeriodAP.description,
                approver_ids: [
                    this.formUpdatePeriodAP.service_manager,
                    // this.formUpdatePeriodAP.th_manager
                ],

                evidences_action_plan: [
                    {
                        evidence_action_plan_id: this.$route.params?.evidenceActionPlan,
                        register_date: this.formUpdatePeriodAP?.period,
                    }
                ],

                comment: null,
            };

            //[TODO*]habilitar la nueva api
            const { ok, error } = await this.proposalUpdateActionPlan({
                id: this.evidenceActionPlan.action_plan.id,
                payload: data,
            });
            if (ok) {
                this.$swal.fire({
                    title: "Solicitud de actualización creada con éxito",
                    icon: "success",
                    confirmButtonColor: "#0039a6",
                    confirmButtonText: "Ok",
                });
                this.$router.push({ name: "ListEvidenceActionPlanDinamic", planTypeId: this.planType.id });
                this.formUpdatePeriodAP = {
                    description: '',
                    period: '',
                    service_manager: '',
                    th_manager: ''
                }
            } else {
                let errorMsm = error?.data?.message;
                errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
                showSnackBar(errorMsm, "error");
            }
            this.loadingSend = false;
        },

        cancelUpdateEvidenceActionPlan: async function () {
            this.formUpdatePeriodAP = {
                description: '',
                period: '',
                service_manager: '',
                th_manager: ''
            },
                this.$router.push({ name: "ListEvidenceActionPlanDinamic", planTypeId: this.planType.id });
        },

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
                (activity) =>
                    activity.module_name === module &&
                    activity.page_name === page &&
                    activity.activity_name === activityName
            );

            return found;
        },

        replyUpdate: async function (confirmed) {
            this.loadingSend = true;
            let data = {
                confirm: confirmed
            };
            const { error } = await this.replyProposalUpdateActionPlan({
                id: this.evidenceActionPlan.action_plan.id,
                payload: data,
            });

            let errorMsm = error?.data?.message;
            errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
            showSnackBar(errorMsm, "error");
            this.loadingSend = false;
            this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });

        },
    },

    beforeDestroy() {
        this.cleanPlanTypeId();
        this.cleanEvidenceActionPlanId();
    },

    components: {
        DateSelectedFieldDinamic,
        // QuestionsSelectedDialog,
        ValidateActivity
    },
};
