<template>
	<ListActionPlanDinamic />
</template>
<script>

import ListActionPlanDinamic from '@/components/action-plan-dinamics/list-action-plan-dinamic/ListActionPlanDinamic.vue'

export default {
	name: 'ListActionPlanDinamicPage',
	components: { ListActionPlanDinamic },
};
</script>
