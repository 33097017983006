import moment from "moment";

export default {
    name: "selectedDatesFieldDinamic",
    data() {
        return {
            loadingSave: false,
            listError: [],
            periodsHeader: [
                { text: "Fechas seleccionados", sortable: false, value: "date" },
                { text: "Acciones", value: "actions", sortable: false },
            ],
            menu: false,
            minDate: moment().toISOString(),
        };
    },
    props: {
        value: Array,
        disabled: Boolean,
        multipleDates: Boolean,
    },
    computed: {
        mapSeletedDates() {
            return this.selectedDates.map((selectDate) => {
                return { date: selectDate };
            });
        },
        selectedDates: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        close() {
            this.dialog = false;
        },
        async deleteItemConfirm(index) {
            this.selectedDates.splice(index, 1);
        },

        formaDate: function (date) {
            try {
                return moment(date).format("DD-MM-YYYY");
            } catch (__) {
                return "-";
            }
        },
    },
};
