<template>
	<ReportActionPlanDinamic />
</template>
<script>

import ReportActionPlanDinamic from '@/components/action-plan-dinamics/report-action-plan-dinamic/ReportActionPlanDinamic.vue'

export default {
	name: 'ReportActionPlanDinamicPage',
	components: { ReportActionPlanDinamic },
};
</script>