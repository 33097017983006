<template>
	<UpdatePlanType />
</template>
<script>

import UpdatePlanType from '@/components/plan-type/update-plan-type/UpdatePlanTypeAction.vue'

export default {
	name: 'UpdatePlanTypePage',
	components: { UpdatePlanType },
};
</script>


<!-- <template>
	<CreatePlanType />
</template>
<script>

import CreatePlanType from '@/components/plan-type/create-plan-type/CreatePlanType.vue'

export default {
	name: 'CreatePlanTypePage',
	components: { CreatePlanType },
};
</script>
 -->
