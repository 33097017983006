import { mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
    name: 'ActionPlanDinamicNotificationPendingTab',
    components: {
    },

    data() {
        return {
            isLoading: false,
            notificationPending: [],
            notificationPagination: null,
            notificationFilter: {
                plan_type_id: this.$route.params.planTypeId,
                status: 'PENDIENTE',
                page: 1,
                limit: 20 || process.env.VUE_APP_PAGINATION_PER_PAGE,
                pagination: true,
            },
            headers: [
                { text: 'Solicitud', sortable: false, value: 'id' },
                { text: 'Fecha de creación de la solicitud', sortable: false, value: 'created_at' },
                { text: 'Usuario', sortable: false, value: 'user' },
                { text: "Acciones", value: "actions", sortable: false },
            ]
        };
    },

    computed: {
    },

    methods: {
        ...mapActions('action_plan', ['listActionPlanUpdatePagination']),

        async getNotificationPending() {
            this.isLoading = true;
            const { error, response } = await this.listActionPlanUpdatePagination(this.notificationFilter);
            if (error) showError(error);
            this.notificationPending = response.data;
            this.notificationPagination = response.pagination;
            this.isLoading = false;
        },

        formatDate(dateString) {

            if (!dateString) {
                return "-";
            }
            const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

            return dateOnly;
        },

        setApprovers(item) {
            if (!item.modification_histories || item.modification_histories.length === 0) {
                return '';
            }

            const approvers = item.modification_histories[0]?.approvers;

            if (!approvers || approvers.length === 0) {
                return '';
            }

            const ids = approvers.map(approver => approver.response_user.fullName);

            return ids.length > 1 ? ids.join(' - ') : ids[0].toString();
        }
    },

    created() {
        this.getNotificationPending();
    },

    mounted() {
    },

    beforeDestroy() {
    },
};