<template>
	<DetailEvidenceActionPlanDinamic />
</template>
<script>

import DetailEvidenceActionPlanDinamic from '@/components/action-plan-dinamics/detail-evidence-action-plan-dinamic/DetailEvidenceActionPlanDinamic.vue'

export default {
	name: 'DetailEvidenceActionPlanDinamicPage',
	components: { DetailEvidenceActionPlanDinamic },
};
</script>
