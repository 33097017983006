import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import NotificationActionPlanDinamicPendingTab from '@/components/action-plan-dinamics/components/notification-action-plan-dinamic-pending-tab/index.vue';
import NotificationActionPlanDinamicAcceptTab from '@/components/action-plan-dinamics/components/notification-action-plan-dinamic-accept-tab/index.vue';
import NotificationActionPlanDinamicDeclineTab from '@/components/action-plan-dinamics/components/notification-action-plan-dinamic-decline-tab/index.vue';

export default {
    name: 'ListActionPlanSatisfactionNotificationDinamic',
    components: {
        NotificationActionPlanDinamicPendingTab,
        NotificationActionPlanDinamicAcceptTab,
        NotificationActionPlanDinamicDeclineTab
    },

    data() {
        return {
            tab: 0,
            isLoadingPlanType: false,
        };
    },

    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Notificaciones",
                    link: true,
                    exact: true,
                    disabled: true,
                    to: {
                        //   name: "DashboardActionPlan",
                    },
                },
            ];
        },
        profiles() {
            // let permissions = [];
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = []
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role)
                    });
                });
            }
            return profiles || [];
        },

        ...mapState("plan_type", ["planType"]),

        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );

            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },
    },

    methods: {
        ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }

            this.isLoadingPlanType = false;
        },
    },

    created() {
        this.getPlanType();
    },

    mounted() {
    },

    beforeDestroy() {
        this.cleanPlanTypeId();
    },
};