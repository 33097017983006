<template>
	<ListEvidencesActionPlanDinamic />
</template>
<script>

import ListEvidencesActionPlanDinamic from '@/components/action-plan-dinamics/list-evidences-action-plan-dinamic/ListEvidencesActionPlanDinamic.vue'

export default {
	name: 'ListEvidencesActionPlanDinamicPage',
	components: { ListEvidencesActionPlanDinamic },
};
</script>