import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import ObservedEvidenceDialog from '../components/observedEvidenceDialogDinamic';
import EvidenceActionPlanDetailDialog from '../../action-plans/components/evidenceActionPlanDetail';
import moment from "moment";
export default {
    name: "DetailEvidenceActionPlanEvidenceDinamic",
    data() {
        return {
            isLoadingPlanType: false,
            isLoading: false,
            loadingApproveEvidence: false,
            observedEvidenceDialog: false,
            selectedItem: null,
            detailEvidenceDialog: false,
        }
    },

    computed: {
        // breadcrumbs() {
        //     [
        //         {
        //           text: "Inicio",
        //           link: true,
        //           exact: true,
        //           disabled: false,
        //           to: {
        //             name: "Dashboard",
        //           },
        //         },
        //         {
        //           text: "Lista de planes de acción por servicio",
        //           link: true,
        //           exact: true,
        //           disabled: false,
        //           to: {
        //             name: "ReportActionPlansSatisfaction",
        //           },
        //         },
        //         {
        //           text: "Lista de evidencias de plan de acción",
        //           link: true,
        //           exact: true,
        //           disabled: false,
        //           to: {
        //             name: 'ListEvidenceActionPlanSatisfaction',
        //           },
        //         },
        //         {
        //           text: "Detalle de evidencia de plan de acción",
        //           link: true,
        //           exact: true,
        //           disabled: true,

        //         },
        //       ] 
        // },
        ...mapState("evidence_action_plan", ["evidenceActionPlan"]),
        evidenceObservedData() {
            if (this.evidenceActionPlan?.status === 'OBSERVADO' && this.evidenceActionPlan?.evidence_action_plan_history_status && this.evidenceActionPlan?.evidence_action_plan_history_status.length) {

                const length = this.evidenceActionPlan?.evidence_action_plan_history_status.length;

                const evidenceActionPlanObserved = this.evidenceActionPlan?.evidence_action_plan_history_status[length - 1];

                return evidenceActionPlanObserved;
            } else {
                return null;
            }
        },

        profiles() {
            // let permissions = [];
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = []
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role)
                        // profile.modules.forEach((module) => {
                        // 	module.pages.forEach((page) => {
                        // 		page.activities.forEach((activity) => {
                        // 			permissions.push({
                        // 				company_name: company?.name,
                        // 				profile_name: profile?.role?.name,
                        // 				module_name: module?.name,
                        // 				page_name: page?.name,
                        // 				activity_name: activity?.name,
                        // 			});
                        // 		});
                        // 	});
                        // });
                    });
                });
            }
            return profiles || [];
        },

        ...mapState("plan_type", ["planType"]),

        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );

            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },
    },

    created() {
        this.getPlanType();
        this.getEvidenceActionPlan();
    },

    methods: {
        ...mapActions("evidence_action_plan", ["getEvidenceActionPlanById", "cleanEvidenceActionPlanId", "confirmObservedEvidenceActionPlan"]),

        ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }

            this.isLoadingPlanType = false;
        },

        async getEvidenceActionPlan() {
            this.isLoading = true;
            const { evidenceActionPlanId } = this.$route.params;
            const { error } = await this.getEvidenceActionPlanById(evidenceActionPlanId);
            if (error) showError(error);
            this.isLoading = false;
        },

        formatDate(dateString) {
            // if (!dateString) {
            //   return { dateOnly: "-", isToday: false };
            // }
            // const formattedDateTime = moment(dateString).format("DD/MM/YYYY HH:mm");
            // const dateOnly = formattedDateTime.split(" ")[0];
            // const isToday = moment().format("DD/MM/YYYY") === dateOnly; // Comparar con la fecha actual

            // return { dateOnly, isToday };

            if (!dateString) {
                return "-";
            }
            // Trata la fecha como un objeto de fecha pura sin conversión de zona horaria
            const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

            return dateOnly;
        },

        observedEvidence() {
            // this.editedItem = Object.assign({}, item);
            this.observedEvidenceDialog = true;
            this.$nextTick(() => {
                document.activeElement.blur();
            });
        },

        confirmEvidence() {
            this.$swal({
                text: '¿Estás seguro de aprobar esta evidencia?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { evidenceActionPlanId } = this.$route.params;
                    const data = {
                        company_id: parseInt(localStorage.getItem("company_id")),
                        evidence_action_plan_id: evidenceActionPlanId,
                        observed: false
                    }

                    const { error } = await this.confirmObservedEvidenceActionPlan(data);
                    if (error) showError(error);

                    this.$router.push({ name: "ControlEvidencesActionPlanDinamic", planTypeId: this.planType.id });
                }
            })
        },

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
                (activity) =>
                    activity.module_name === module &&
                    activity.page_name === page &&
                    activity.activity_name === activityName
            );

            return found;
        },

        detailEvidence() {
            this.selectedItem = this.evidenceActionPlan;
            this.detailEvidenceDialog = true;
            this.$nextTick(() => {
                document.activeElement.blur();
            });
        }
    },

    beforeDestroy() {
        this.cleanEvidenceActionPlanId();
    },

    components: {
        ObservedEvidenceDialog,
        EvidenceActionPlanDetailDialog
    }
}