var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isLoadingPlanType)?_c('v-container',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100vh"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1):_vm._e(),(_vm.isAuthorized && _vm.evidenceActionPlan)?_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,3681283568)}),_c('v-card',{staticClass:"pa-4 px-md-8 mb-1",attrs:{"rounded":"lg","flat":""}},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 m-b-4"},[_vm._v("Cargar evidencia")]),_c('v-divider',{staticClass:"mt-4 mb-4"}),(_vm.evidenceActionPlan)?_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Plan de acción:")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.evidenceActionPlan?.action_plan?.description)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Responsable")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.evidenceActionPlan?.action_plan?.responsible?.fullName)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Gerencia:")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.evidenceActionPlan?.action_plan?.service.management_name)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Servicio:")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.evidenceActionPlan?.action_plan?.service.name)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Estado:")]),_c('td',{staticClass:"pa-1"},[_c('v-chip',{attrs:{"color":_vm.setColorStatusDetailActionPlan(
                        _vm.evidenceActionPlan?.status
                      )?.background,"text-color":_vm.setColorStatusDetailActionPlan(
                        _vm.evidenceActionPlan?.status
                      )?.color}},[_vm._v(" "+_vm._s(_vm.setColorStatusDetailActionPlan( _vm.evidenceActionPlan?.status )?.name)+" ")])],1)]),_c('tr',[(_vm.evidenceActionPlan?.status === 'OBSERVADO')?_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Comentario de observación: ")]):_vm._e(),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.commentObservation)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Periodo:")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.evidenceActionPlan?.register_date))+" ")])]),(
                  (_vm.evidenceActionPlan?.evidence_plan_action_details.length &&
                    _vm.evidenceActionPlan?.status === 'PENDIENTE') ||
                  _vm.evidenceActionPlan?.status === 'COMPLETADO'
                )?_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Estado:")]),_c('td',[_c('div',_vm._l((_vm.evidenceActionPlan?.evidence_plan_action_details),function(item){return _c('v-chip',{key:item.id,attrs:{"href":item.evidence_file,"color":"primary","outlined":"","small":"","target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-link")]),_vm._v(" Archivo ")],1)}),1)])]):_vm._e()])]):_vm._e()],1)],1)],1),(
        _vm.evidenceActionPlan?.status === 'NO_COMPLETADO' ||
        _vm.evidenceActionPlan?.status === 'OBSERVADO'
      )?_c('v-card',{staticClass:"mt-4"},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('drag-drop-file',{staticClass:"ml-4 mr-4 mb-4",attrs:{"maxsize":10,"extensions":[
                '.pdf',
                '.ppt',
                '.pptx',
                '.xls',
                '.xlsx',
                '.doc',
                '.docx',
                '.png',
                '.jpg',
                '.jpeg',
              ],"attrs":{
                accept:
                  'application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/jpg',
                multiple: true,
              }},model:{value:(_vm.uploadEvidenceForm.evidence_action_plan),callback:function ($$v) {_vm.$set(_vm.uploadEvidenceForm, "evidence_action_plan", $$v)},expression:"uploadEvidenceForm.evidence_action_plan"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticClass:"ml-5",attrs:{"rules":_vm.commentRules,"label":"Comentario","outlined":""},model:{value:(_vm.uploadEvidenceForm.comment),callback:function ($$v) {_vm.$set(_vm.uploadEvidenceForm, "comment", $$v)},expression:"uploadEvidenceForm.comment"}})],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.cancelUploadEvidence()}}},[_vm._v(_vm._s(_vm.setButtonName))]),(
            _vm.evidenceActionPlan?.status === 'PENDIENTE' ||
            (_vm.evidenceActionPlan?.status === 'COMPLETADO' &&
              _vm.isAllowed(
                'tipoPlanDinamico',
                'listaPlanesAccion',
                'eliminarEvidencias'
              ))
          )?_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"color":"primary","loading":_vm.loadingDeleteEvidence},on:{"click":function($event){return _vm.deleteEvidence()}}},[_vm._v("Borrar evidencias")]):_vm._e(),(
            _vm.evidenceActionPlan?.status === 'NO_COMPLETADO' ||
            _vm.evidenceActionPlan?.status === 'OBSERVADO'
          )?_c('v-btn',{staticClass:"primary mr-4 mb-4",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.saveEvidenceActionPlan()}}},[_vm._v("Guardar evidencia")]):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }