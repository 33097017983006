<template>
	<DetailActionPlanDinamicGroupedService />
</template>
<script>

import DetailActionPlanDinamicGroupedService from '@/components/action-plan-dinamics/detail-action-plan-dinamic-grouped-service/DetailActionPlanDinamicGroupedService.vue'

export default {
	name: 'DetailActionPlanDinamicGroupedServicePage',
	components: { DetailActionPlanDinamicGroupedService },
};
</script>
