<template>
	<ListPlanType />
</template>
<script>

import ListPlanType from '@/components/plan-type/list-plan-type/ListPlanType.vue'

export default {
	name: 'ListPlanTypePage',
	components: { ListPlanType },
};
</script>
