<template>
	<NotificationActionPlanDinamic />
</template>
<script>

import NotificationActionPlanDinamic from '@/components/action-plan-dinamics/notification-action-plan-dinamic/NotificationActionPlanDinamic.vue'

export default {
	name: 'NotificationActionPlanDinamicPage',
	components: { NotificationActionPlanDinamic },
};
</script>
