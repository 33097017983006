import * as securityService from "./service/security.service";
import textTransform from "@/helpers/textTransform.js";

const state = {
  user: null,
  usersActive: [],
  managements: [],
  costCenters: [],
  isPendingUsers: false,
  costCentersUser: [],
  managementsUser: [],
  allManagementsState: [],
  filteredCostCenters: [],
  allProfiles: [],
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USERS_ACTIVE(state, payload) {
    state.usersActive = [...payload];
  },
  SET_MANAGEMENTS(state, payload) {
    state.managements = Array.isArray(payload) ? [...payload] : [];
  },
  SET_COST_CENTERS(state, payload) {
    state.costCenters = [...payload];
  },
  SET_IS_PENDING_USERS(state, payload) {
    state.isPendingUsers = payload;
  },
  SET_COST_CENTERS_USER(state, payload) {
    state.costCentersUser = [...payload];
  },
  SET_MANAGEMENT_USER(state, payload) {
    state.managementsUser = [...payload];
  },
  SET_ALL_COST_CENTERS(state, payload) {
    state.allCostCenters = payload;
  },
  SET_ALL_MANAGEMENTS(state, payload) {
    state.allManagementsState = [...payload];
  },
  SET_ALL_PROFILES(state, payload) {
    state.allProfiles = [...payload];
  },
};

const actions = {
  async getUsersActive({ state, commit }, data) {
    try {
      if (!state.isPendingUsers) {
        commit("SET_IS_PENDING_USERS", true);
        commit("SET_USERS_ACTIVE", []);
        const response = await securityService.usersActive(data);

        let users = response?.data;
        if (Array.isArray(users)) {
          users = users.map((user) => {
            return {
              ...user,
              fullName: textTransform(user.fullName, "capitalize"),
            };
          });
        }
        commit("SET_USERS_ACTIVE", users || []);
        return { ok: true, response };
      }
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    } finally {
      commit("SET_IS_PENDING_USERS", false);
    }
  },

  async getUser({ commit }, data) {
    try {
      commit("SET_USER", null);
      const response = await securityService.usersActive(data);

      let users = response?.data;
      let user = null;
      if (Array.isArray(users)) {
        users = users.map((user) => {
          return {
            ...user,
            fullName: textTransform(user.fullName, "capitalize"),
          };
        });
        user = users[0];
      }
      commit("SET_USER", user);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getManagements({ commit }, data) {
    try {
      const { user_id } = data;
      if (user_id) {
        let costCenters = [];
        const response = await securityService.getManagements(data);
        const managements = response?.data?.managements;
        managements.forEach((management) => {
          management.costCenters.forEach((ceco) => {
            if (!costCenters.some((item) => item.id == ceco.id)) {
              costCenters.push({
                ...ceco,
                management_id: management?.id,
                name: `${ceco.name} - ${ceco.pep_code}`,
              });
            }
          });
        });
        commit("SET_MANAGEMENTS", managements);
        commit("SET_COST_CENTERS", costCenters);
        return { ok: true, response: response?.data };
      }
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getCostCenter({ commit }, data) {
    try {
      commit("SET_COST_CENTERS", []);
      const response = await securityService.getAllCostCenter(data);
      commit("SET_COST_CENTERS", response?.data);
      return { ok: true, response: response?.data };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getProfiles({ commit }, data) {
    try {
      commit("SET_ALL_PROFILES", []);
      const response = await securityService.getProfles(data);
      commit("SET_ALL_PROFILES", response?.roles);
      return { ok: true, response: response?.roles };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async getCostCenterUser({ commit }, data) {
    try {
      commit("SET_COST_CENTERS_USER", []);
      commit("SET_MANAGEMENT_USER", []);
      commit("SET_ALL_COST_CENTERS", []);
      const response = await securityService.getCostCenterByUser(data);
      commit("SET_MANAGEMENT_USER", response?.managements);
      commit("SET_COST_CENTERS_USER", response?.costCenters);
      commit("SET_ALL_COST_CENTERS", response?.costCenters);
      return { ok: true, response: response }
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async allManagements({ commit }, data) {
    try {
      const response = await securityService.getAllManagement(data);

      commit("SET_ALL_MANAGEMENTS", response?.data?.managements);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  cleanManagements({ commit }) {
    commit("SET_MANAGEMENTS", []);
    commit("SET_COST_CENTERS", []);
  },

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },
};

const getters = {
  getUsers(state) {
    let items = [];
    if (Array.isArray(state.usersActive)) {
      items = state.usersActive.map((user) => {
        return { ...user, name: user.fullName };
      });
    }
    return items || [];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
