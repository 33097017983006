<template>
	<UploadEvidenceActionPlanDinamic />
</template>
<script>

import UploadEvidenceActionPlanDinamic from '@/components/action-plan-dinamics/upload-evidence-action-plan-dinamic/UploadEvidenceActionPlanDinamic.vue'

export default {
	name: 'UploadEvidenceActionPlanDinamicPage',
	components: { UploadEvidenceActionPlanDinamic },
};
</script>