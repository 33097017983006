<template>
	<DashboardActionPlanDinamic />
</template>
<script>

import DashboardActionPlanDinamic from '@/components/action-plan-dinamics/dashboard-action-plan-dinamic/DashboardActionPlanDinamic.vue'

export default {
	name: 'DashboardActionPlanDinamicPage',
	components: { DashboardActionPlanDinamic },
};
</script>
