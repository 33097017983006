<template>
	<DetailPlanType />
</template>
<script>

import DetailPlanType from '@/components/plan-type/detail-plan-type/DetailPlanType.vue'

export default {
	name: 'DetailPlanTypePage',
	components: { DetailPlanType },
};
</script>
