import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
    name: 'ListEvidenceActionPlanDinamic',
    data() {
        return {
            isLoadingPlanType:false,
            loadingCostCenter: false,
            loadingActionPlan: false,
            isLoadingUsers: false,
            headers: [
                { text: "Periodo", sortable: false, value: "register_date" },
                { text: "Plan de acción", sortable: false, value: "action_plan.description" },
                { text: "Creador", sortable: false, value: "creator_id.fullName" },
                { text: "Servicio", sortable: false, value: "action_plan.service.name" },
                { text: "Gerencia", sortable: false, value: "action_plan.management.name" },
                { text: "Estado", sortable: false, value: "status" },
                { text: "Acciones", value: "actions", sortable: false },
            ],
            evidenceActionPlanFilters: {
                page: 1,
                limit: 20 || process.env.VUE_APP_PAGINATION_PER_PAGE,
                pagination: true,
                plan_type_id: this.$route?.params?.planTypeId,
                //
                status: 'PENDIENTE',
                service: null,
                responsible: null,
            },
            status: [
                { name: "COMPLETADO", value: "COMPLETADO" },
                { name: "PENDIENTE", value: "PENDIENTE" },
                { name: "NO COMPLETADO", value: "NO_COMPLETADO" },
                { name: "OBSERVADO", value: "OBSERVADO" },
            ],
        }
    },

    created() {
        this.getPlanType();
        this.getCostCenterByUser();
        this.getEvidenceActionPlan();
        this.loadUsers();
    },

    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Lista de planes de acción por servicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "ReportActionPlansDinamic",
                        planTypeId:this.$route?.params?.planTypeId
                    },
                },
                {
                    text: "Lista de evidencias de plan de acción",
                    link: true,
                    exact: true,
                    disabled: true,
                    // to: {
                    //   name: 'InventoryHome',
                    // },
                },
            ];
        },
        ...mapState("auth", ["user"]),
        ...mapState("security", [
            // "managements", 
            // "costCenters"
            "usersActive",
            "costCentersUser"
        ]),
        // ...mapState("action_plan", ["actionPlansPagination", "actionPlans"]),
        ...mapState("evidence_action_plan", ["evidencesActionPlan", "evidencesActionPlanPagination"]),
        profiles() {
            // let permissions = [];
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = []
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role)
                        // profile.modules.forEach((module) => {
                        // 	module.pages.forEach((page) => {
                        // 		page.activities.forEach((activity) => {
                        // 			permissions.push({
                        // 				company_name: company?.name,
                        // 				profile_name: profile?.role?.name,
                        // 				module_name: module?.name,
                        // 				page_name: page?.name,
                        // 				activity_name: activity?.name,
                        // 			});
                        // 		});
                        // 	});
                        // });
                    });
                });
            }
            return profiles || [];
        },

        ...mapState("plan_type", ["planType"]),

        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );

            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },
    },

    methods: {
        ...mapActions("security", [
            // "getManagements",
            "getCostCenterUser",
            "getUsersActive"
        ]),

        // ...mapActions("action_plan", ["listActionPlanPagination", "deletedActionPlan"]),

        ...mapActions("evidence_action_plan", [
            "listActionPlanPagination",
            "reverseEvidence"
        ]),

        ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }

            this.isLoadingPlanType = false;
        },

        loadUsers: async function () {
            this.isLoadingUsers = true;
            await this.getUsersActive();
            this.isLoadingUsers = false;
        },

        async getCostCenterByUser() {
            this.loadingCostCenter = true;
            const { error } = await this.getCostCenterUser({
                user_id: this.user.id,
            });
            if (error) showError(error);
            this.loadingCostCenter = false;
        },

        async getEvidenceActionPlan() {
            this.loadingActionPlan = true;
            const { error } = await this.listActionPlanPagination(
               this.evidenceActionPlanFilters
            );
            if (error) showError(error);
            this.loadingActionPlan = false;
        },

        formatDate(dateString) {

            if (!dateString) {
                return "-";
            }

            const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

            return dateOnly;
        },

        setColorStatusEvidenceActionPlan(status) {
            switch (status) {
                case "EN_CURSO":
                    return {
                        background: "blue lighten-5",
                        color: "blue",
                        name: "EN CURSO",
                    };
                case "COMPLETADO":
                    return {
                        background: "green lighten-5",
                        color: "green",
                        name: status,
                    };
                case "ATRASADO":
                    return {
                        background: "grey lighten-3",
                        color: "grey",
                        name: status,
                    };
                case "NO_COMPLETADO":
                    return {
                        background: "grey lighten-2",
                        color: "grey",
                        name: "NO COMPLETADO",
                    };
                case "PENDIENTE":
                    return {
                        background: "blue lighten-5",
                        color: "blue",
                        name: "PENDIENTE",
                    };
                case "OBSERVADO":
                    return {
                        background: "yellow lighten-4",
                        color: "yellow darken-2",
                        name: "OBSERVADO",
                    };
            }
        },

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
                (activity) =>
                    activity.module_name === module &&
                    activity.page_name === page &&
                    activity.activity_name === activityName
            );

            return found;
        },

        async confirmApprovedEvidence(evidenceActionPlan) {
            this.$swal({
                text: '¿Estás seguro de revertir el estado de esta evidencia confirmada?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí',
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const { error } = await this.reverseEvidence(evidenceActionPlan.id);
                    if (error) showError(error);

                    await this.getEvidenceActionPlan()

                }
            })
        },
    },
    components: {

    }
};