export default {
    name: "SelectedGroupingsDialog",
    props: {
        groupings: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        serviceId: Number,
        criteriaName: String,
    },
    data() {
        return {
            dialog: false,
            searchTerm: null,
            searchTimeout: null,
        };
    },
    computed: {
        selectedGroupings: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },

        filteredGroupings() {
            let result = this.groupings;

            if (this.searchTerm) {
                const lowerSearch = this.searchTerm.toLowerCase();
                result = result.filter(group => {
                    if (group.grouping_name && group.grouping_name.toLowerCase().includes(lowerSearch)) {
                        return true;
                    }
                    return group.field_groups?.some(
                        fg => fg.value && fg.value.toLowerCase().includes(lowerSearch)
                    );
                });
            }

            if (this.serviceId) {
                const numericServiceId = parseInt(this.serviceId, 10);
                result = result.filter(group => {
                    if (!group.services || group.services.length === 0) {
                        return true;
                    }
                    return group.services.some(s => s.service_id === numericServiceId);
                });
            }

            return result;
        },
    },
    methods: {
        close() {
            this.dialog = false;
        },

        isGroupingSelected(groupId) {
            return this.selectedGroupings.some(g => g?.id === groupId);
        },

        addGrouping(grouping) {
            if (!this.isGroupingSelected(grouping.id)) {
                this.selectedGroupings.push(grouping);
            }
        },

        removeGrouping(groupId) {
            const index = this.selectedGroupings.findIndex(g => g.id === groupId);
            if (index > -1) {
                this.selectedGroupings.splice(index, 1);
            }
        },

        // Búsqueda con debounce
        debounceSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                // No hay API call, simplemente filtrar en computed
                // Si quisieras pedir datos al servidor, podrías implementar la API aquí
            }, 500);
        },
    },
};