import moment from "moment";

export default {
  name: "selectedDateFieldDinamic",
  data() {
    return {
      menu: false,
      minDate: moment().toISOString(),
      dateToUpdate: null,
      periodHeader: [
        { text: "Fecha a actualizar", sortable: false, value: "dateToUpdate" },
        { text: "Nueva fecha", sortable: false, value: "newDate" },
      ],
      headers: [
        { text: "Fechas de plan de acción", value: "register_date" },
      ],
    };
  },
  props: {
    evidences: {
      type: Array,
      required: true,
    },
    idEvidenceActionPlan: Number || String,
    value: String,
    disabled: Boolean,
  },
  created() {
  },
  computed: {
    mappedSelectedDate() {
      return { dateToUpdate: this.dateToUpdate, newDate: this.selectedDate };
    },
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    filteredEvidenceActionPlanUpdated() {
      if (this.evidences?.length === 1) {
        return this.evidences;
      }

      const excludedRecord = this.evidences?.find(record => String(record.id) === String(this.idEvidenceActionPlan));
      if(excludedRecord){
        this.dateToUpdate = this.formatDate(excludedRecord.register_date);
      }

      return this.evidences?.filter(record => String(record.id) !== String(this.idEvidenceActionPlan));
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment.utc(dateString).format("YYYY-MM-DD");
    },
  },
};