import { mapActions, mapState } from "vuex";
import { showError, showSnackBar } from "@/helpers/globalHelpers";
import DatesSelectedDialogDinamic from "../components/selectedDatesFieldDinamic";
import FactorSelectedDialogDinamic from "../components/factorSelectedDialogDinamic";
import moment from "moment";

export default {
    name: "CreateActionPlanDinamic",
    components: {
        DatesSelectedDialogDinamic,
        FactorSelectedDialogDinamic,
    },
    data() {
        return {
            loadingSend: false,
            searchFactor: "",
            searchText: "",
            isLoadingPlanType: false,
            isLoadingUsers: false,
            isLoadingManagements: false,
            isValidForm: null,

            // Fechas seleccionadas (otro componente)
            selectedDates: [],

            // ¡Ahora usamos selectedGroupings en vez de selectedFactors!
            selectedGroupings: [],

            previousServiceId: null,
            requiredRule: [(v) => !!v || "Campo requerido"],

            formAP: {
                company_id: parseInt(localStorage.getItem("company_id")),
                service_id: null,
                description: "",
                responsible: null,
                recipients: null,
            },

            textRules: [
                v => !!v || 'Campo requerido'  // obligar a ingresar algo
            ],
            numericRules: [
                v => !!v || 'Campo requerido',
                v => /^[0-9]*(\.[0-9]+)?$/.test(v) || 'Debe ser numérico'
            ]
        };
    },
    computed: {
        ...mapState("plan_type", ["planType"]),
        ...mapState("auth", ["user"]),
        ...mapState("security", ["usersActive", "costCentersUser"]),

        profiles() {
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = [];
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role);
                    });
                });
            }
            return profiles || [];
        },

        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );
            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },

        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: { name: "Dashboard" },
                },
                {
                    text: "Planes de acción",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: { name: "ListEvidenceActionPlanDinamic" },
                },
                {
                    text: "Crear plan de acción",
                    link: true,
                    exact: true,
                    disabled: true,
                },
            ];
        },

        filteredSelectedGroupings() {
            if (!this.searchFactor) {
                return this.selectedGroupings;
            }
            const lowerSearch = this.searchFactor.toLowerCase();

            // Filtra cada grouping si sus field_groups contienen el texto buscado.
            return this.selectedGroupings.filter(group => {
                if (!group.field_groups) return false;
                return group.field_groups.some(fieldGroup => {
                    const fieldName = fieldGroup.field.field_name?.toLowerCase() || '';
                    const fieldValue = fieldGroup.value?.toLowerCase() || '';
                    // Coincidencia en el nombre del campo o el valor
                    return (
                        fieldName.includes(lowerSearch) ||
                        fieldValue.includes(lowerSearch)
                    );
                });
            });
        }
    },

    watch: {
        'formAP.service_id': {
          handler(newVal, oldVal) {
            if (newVal === null || newVal === undefined) {
              this.selectedGroupings = [];
            } else if (newVal !== oldVal) {
              if (!this.cancelingChange) {
                this.previousServiceId = oldVal;
              }
              this.cancelingChange = false;
            }
          },
          immediate: false
        }
      },

    methods: {
        ...mapActions("plan_type", ["getPlantypeById", "cleanPlanTypeId"]),
        ...mapActions("security", ["getUsersActive", "getCostCenterUser"]),
        ...mapActions("action_plan", ["createActionPlan"]),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId);
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }
            this.isLoadingPlanType = false;
        },

        async loadUsers() {
            this.isLoadingUsers = true;
            await this.getUsersActive();
            this.isLoadingUsers = false;
        },

        async loadManagements() {
            this.isLoadingManagements = true;
            await this.getCostCenterUser({ user_id: this.user.id });
            this.isLoadingManagements = false;
        },

        customFilter(item, queryText, itemText) {
            const searchTerms = queryText.toLowerCase().split(" ");
            const fullName = itemText.toLowerCase();
            return searchTerms.every((term) => fullName.includes(term));
        },

        handleServiceChange(newServiceId) {
            // Si ya hay agrupamientos seleccionados y existe un service_id
            if (this.selectedGroupings.length > 0 && this.formAP.service_id) {
                this.$swal
                    .fire({
                        title: "¿Estás seguro de seleccionar otro servicio?",
                        text: "Seleccionar otro servicio limpiará los agrupamientos seleccionados. ¿Deseas continuar?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#0039a6",
                        confirmButtonText: "Sí, cambiar",
                        cancelButtonText: "No, cancelar",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.selectedGroupings = [];
                            this.formAP.service_id = newServiceId;
                        } else {
                            this.cancelingChange = true;
                            this.formAP.service_id = this.previousServiceId;
                        }
                    });
            } else {
                this.formAP.service_id = newServiceId;
            }
        },

        clearSearchText() {
            this.searchText = "";
        },

        validationForm() {
            const formCompleted = this.$refs.form.validate();
            const periodCompleted = this.selectedDates.length > 0;
            const groupingsCompleted = this.selectedGroupings.length > 0;

            // Validaciones estándar
            if (!formCompleted) {
                showSnackBar("Completar el formulario", "warning");
                return false;
            }
            if (!periodCompleted) {
                showSnackBar("Completar el período", "warning");
                return false;
            }
            if (!groupingsCompleted) {
                showSnackBar("Seleccionar factores (agrupamientos)", "warning");
                return false;
            }

            // Verificar que TODOS los campos con is_required = false tengan valor
            let missingFields = [];
            this.selectedGroupings.forEach(grouping => {
                grouping.field_groups.forEach(fieldGroup => {
                    const { is_required, field_name } = fieldGroup.field || {};
                    if (!is_required) {
                        const val = fieldGroup.value ? fieldGroup.value.toString().trim() : "";
                        if (!val) {
                            missingFields.push(field_name);
                        }
                    }
                });
            });

            if (missingFields.length > 0) {
                const camposFaltantes = missingFields.join(", ");
                showSnackBar(
                    `Por favor, complete los siguientes campos opcionales: ${camposFaltantes}`,
                    "warning"
                );
                return false;
            }

            // Si llega hasta aquí, pasa TODAS las validaciones
            this.saveActionPlan();
        },

        removeGrouping(groupingId) {
            const index = this.selectedGroupings.findIndex(
                (e) => e?.id === groupingId
            );
            if (index > -1) this.selectedGroupings.splice(index, 1);
        },

        saveActionPlan: async function () {
            this.loadingSend = true;
            let data = {
                ...this.formAP,
                plan_type_id: this.planType.id,
                field_selections: [],
                periodicity: [],
            };

            this.selectedDates.forEach((date) => {
                data.periodicity.push({
                    period_date: moment(date).toISOString(),
                });
            });
            this.selectedGroupings.forEach((grouping) => {
                data.field_selections.push({
                    grouping_id: grouping.id,
                    group_fields: grouping.field_groups.map(fieldGroup => { return { field_group_id: fieldGroup.id, key: fieldGroup.field.field_name, value: fieldGroup.value } })
                });
            });
              const { ok, error } = await this.createActionPlan(data);
              if (ok) {
                this.$swal.fire({
                  title: "¡El plan de acción fue creado con éxito!",
                  text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
                  icon: "success",
                  confirmButtonColor: "#0039a6",
                  confirmButtonText: "Ok",
                });
                this.$router.push({ name: "ListEvidenceActionPlanDinamic", planTypeId: this.planType.id,});
              } else {
                let errorMsm = error?.data?.message;
                errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
                showSnackBar(errorMsm, "error");
              }
            this.loadingSend = false;
        },
    },

    created() {
        this.getPlanType();
        this.loadUsers();
        this.loadManagements();
    },
    mounted() {
    },
    beforeDestroy() {
    },
};