<template>
	<CreatePlanType />
</template>
<script>

import CreatePlanType from '@/components/plan-type/create-plan-type/CreatePlanType.vue'

export default {
	name: 'CreatePlanTypePage',
	components: { CreatePlanType },
};
</script>
